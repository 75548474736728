import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { zippo } from "../utils/utilityFunctions";
import Layout from "../components/Layout";
import {  Container, 
          Grid, 
          makeStyles, 
          createStyles, 
          Typography,
          option,
          FormHelperText,
          FormControl,
          Select,
          TextField,
          Button,
          Modal,
          Link,
          Hidden,
        }  from "@material-ui/core/";
import CancelIcon from '@material-ui/icons/Cancel';
import { StaticImage } from "gatsby-plugin-image";

import ScriptEditorImage from "../images/notes.svg";
import PhotoGalleryImage from "../images/images.svg";
import LoginImage from "../images/key.svg";

const muiStyles = makeStyles((theme) => {

  const inputTheme = {
    backgroundColor: "#2A2A2A",
    color: "#ffffff",
    borderRadius: "4px",
    border: "1px solid #ffffff",
  };

  const headingTheme = {
    color: "#000000",
    fontFamily: 'Arial',
    fontWeight: '700',
  }

  return createStyles({
    imageWrapper: {
      display: 'grid',
    },
    heroImage: {
      gridArea: '1/1',
      maxHeight: '550px',
    },
    heroOverlay: {
      opacity: '0.41',
      backgroundColor: '#FFFFFF',
      gridArea: '1/1',
    },
    heroContentWrapper: {
      gridArea: "1/1",
      position: "relative",
      placeItems: "center",
      display: "grid",
    },
    heroContent: {
      height: "100%",
    },
    heroHeadline:{
      ...headingTheme,
      textAlign: 'center',
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    heroSubHeader: {
      color: "#000000",
      textAlign: 'center',
      lineHeight: '1.1875rem',
      fontSize: '0.9907rem',
      marginTop: '-5px',
    },
    getQuoteForm: {
      textAlign: 'center',
    },
    getQuoteFormSelect: {
      ...inputTheme,
      '&& option, && optgroup': {
        ...inputTheme,
      },
      '&& .MuiSelect-icon': {
        color: '#FFFFFF',
      }
    },
    getQuoteZipcode:{
      ...inputTheme,
    },
    getQuoteFormSubmit:{
      padding: '15px 32px',
      backgroundColor: '#1f3864',
      borderRadius: 0,
      fontWeight: 700,
      '&&:hover': {
        backgroundColor: '#133063',
      }
    },
    inputError: {
      color: '#FFFFFF',
    },
    tipsContainer: {
      textAlign: 'center',
      padding: '30px 45px',
    },
    tipsHeading:{
      ...headingTheme,
      lineHeight: '2.375rem',
      fontSize: '1.4861rem',
    },
    p: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
    },
    articleImage: {
      gridArea: '1/1',
      height: '175px',
    },
    articleTitle: {
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'left',
    },
    articleExcerpt: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      textAlign: 'left',
    },
    articleReadMore: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      color: '#1c6bbd',
      textAlign: 'justify',
      cursor: 'pointer',
    },
    stepsHeading:{
      ...headingTheme,
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    stepTitle:{
      ...headingTheme,
      lineHeight: '1.5625rem',
      fontSize: '1.1146rem',
      marginTop: '20px',
    },
    stepText:{
      lineHeight: '1.25rem',
      fontSize: '0.8669rem',
    },
    stepCircle: {
      border: 0,
      background: 'rgb(240,243,245)',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    stepIcon: {
      height: '3.8125rem',
      width: '3.8125rem',
    },
    modal: {
      overflowY: 'scroll',
      padding:"50px",
      backgroundColor: 'rgba(0,0,0,0.65)',
      [theme.breakpoints.down('sm')]: {
        padding:"0px",
      },
    },
    modalWrapper: {
      padding:"50px",
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        padding:"20px",
      },
    },
    modalCloseIcon: {
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      fontSize: '2.5em',
      border: '1px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
    },
    modalContent: {
      backgroundColor: '#ffffff',
      border: '0px solid #000',
      padding: '20px',
      textAlign: 'left',
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      color: '#37465A',
      borderRadius: '10px',
      position: 'relative',
      outline: 'none',
    },
    footerGetQuote: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    footerGetQuoteInputError: {
      color: 'red',
    }
  });
});

// yup validation schema -- overkill but easy to use
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .min(5, "Zipcode must be 5 characters")
    .max(5, "Zipcode must be 5 characters")
    .matches(/(^\d{5}$)/, "Please enter a valid zipcode")
    .required("Zip Code is required"),
  vertical: yup.string().required("This field is required"),
});

const GetQuoteForm = ({className, inputErrorClassName, inputLayout = "default"}) => {
  const [validZip, setValidZip] = useState();
  const [zippoResponse, setZippoResponse] = useState();

  const classes = muiStyles();

  // react-hook-form useForm Hook
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (validZip) {
      const { city, state } = zippoResponse;

      // performs the redirect via Gatsby's navigate method.  We pass the state to be stored in sessionStorage on the TYP
      navigate("/thankyou/", { state: { ...data, city, state } });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const noString = value.replace(/\D/g, "");

    // prevents strings in this input
    setValue("zipcode", noString, {
      shouldValidate: true,
      shouldDirty: true,
    });    

    if (value && value.length === 5) {

      // calls the zippo API to get the city/state which gets stored in sessionStorage
      zippo(value).then((res) => {
        if (res) {
          clearErrors("zipcode");

          // zipcode input is not a controlled input so we ref the DOM to remove the class. 
          document.getElementById("zipcode").classList.remove("border-error");
          setZippoResponse(res);
          setValidZip(true);
        } else {
          setValidZip(false);
        }
      });
    }
  };

  useEffect(() => {
    // can't be a falsy check here need to check for explicit false value 
    if (validZip === false) {
      setError("zipcode", {
        type: "manual",
        message: "Please enter a valid zipcode.",
      });
    }
  }, [validZip, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Grid container justify={inputLayout === "inline" ? "center" : "left"}>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 11} >
            <FormControl variant="outlined" fullWidth>
              <Select
                id="quoteType"
                displayEmpty
                defaultValue=""
                native
                className={classes.getQuoteFormSelect}
                {...register("vertical")}
              >
                <option value="" disabled>
                  Save On Home Needs
                </option>
                <option value="refinance">Refinance</option>
                <option value="purchase">Home Mortgage</option>
                <option value="dpa">Down Payment Assistance (DPA)</option>
                <option value="rto">Rent to Own</option>
                <optgroup label="Insurance">
                  <option value="auto">Auto Insurance</option>
                  <option value="home">Home Insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="medsup">Medicare Support</option>
                </optgroup>
                <option value="solar">Solar</option>
                <option value="windows">Windows</option>
                <option value="roofing">Roofing</option>
              </Select>
              <FormHelperText className={inputErrorClassName || classes.inputError}>{errors.vertical?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 11} >
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="zipcode"
                placeholder="Zip Code"
                name="zipcode"
                variant="outlined"
                {...register("zipcode")}
                onChange={handleChange}
                InputProps={{
                  className: classes.getQuoteZipcode,
                }}
                autoComplete="off"
                value={getValues("zipcode")}
              />
              <FormHelperText className={inputErrorClassName ||  classes.inputError}>{errors.zipcode?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 5} justify={inputLayout === "inline" ? "flex-end" : "flex-start"}>
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} md={inputLayout === "inline" ? 11 : 10} >
            <Button variant="contained" color="primary" type="submit" className={classes.getQuoteFormSubmit} fullWidth={inputLayout === "inline" ? true : false}>
              Get Quote
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const Hero = () => {
  const classes = muiStyles();
  return (
    <div className={classes.imageWrapper}>
      <StaticImage
        className={classes.heroImage}
        layout="fullWidth"
        alt="Homeowners Savings"
        src={
          "../images/thereliefsupport-header.jpg"
        }
        formats={["auto", "webp", "avif"]}
        objectPosition="center center"
      />
      {/* <div className={classes.heroOverlay}/> */}
      <Container className={classes.heroContentWrapper} maxWidth="md">
        <Grid container direction="row"
          justify="flex-start"
          alignItems="center" 
          className={classes.heroContent}>
          <Grid item xs={1}>&nbsp;</Grid>
          <Grid item xs={12} sm={10} md={8} lg={6} textAlign="left">
            <br/>
            <Typography variant="h1" component="h1" className={classes.heroHeadline}>
              The Relief Support
            </Typography>
            <p className={classes.heroSubHeader}>
              <strong>
              Help is available now!
              </strong>
              <br/>
              <Hidden smDown><br/><br/></Hidden>
            </p>
            <GetQuoteForm className={classes.getQuoteForm}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Tips = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#fbfbfb',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h5" component="h5" className={classes.tipsHeading}>
          Bills can get out of hand fast; relief is here for you!
        </Typography>
        <p className={classes.p}>
          We help customers get support with many of the expenses in life like mortgage and refi needs, insurance premiums and home services. We can connect you with a network of local professionals to see how you can save today! We’re in the market to help you save money.
        </p>
      </Container>
    </div>
  )
};

const Blogs = ( { setModalProps }) => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Grid container  spacing={6}>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="Common Reasons for High Car Insurance Expenses"
              src={"../images/thereliefsupport-article1.png"}
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              Common Reasons for High Car Insurance Expenses
            </Typography>
            <p className={classes.articleExcerpt}>
              If your car insurance rates seem high, there could be reasons for it. In general, insurance companies charge higher rates for higher liabilities. In other words, the more likely it is that they’ll…
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'Common Reasons for High Car Insurance Expenses',
                description: 'Common Reasons for High Car Insurance Expenses',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Common Reasons for High Car Insurance Expenses</Typography>
                    <p>
                      By JMcHood March 1, 2021
                    </p>
                    <p>
                      If your car insurance rates seem high, there could be reasons for it. In general, insurance companies charge higher rates for higher liabilities. In other words, the more likely it is that they’ll have to pay a claim, the more they’ll charge you every month. But there are certain factors that could make your car insurance more expensive.
                    </p>
                    <p>
                     Keep reading to find out the top reasons.
                    </p>
                    <p>
                      <strong>Young Drivers</strong><br/>
                      Young drivers pose a high risk to insurance companies. This usually means high rates. Boys tend to pay much higher rates than girls. The Insurance Institute for Highway Safety claims that teens ages 16-19 are at the highest risk of causing a fatal car accident than older drivers.
                    </p>
                    <p>
                      Typically, you’ll pay what feels like inflated car insurance rates until you are 25-years old. Then, insurance rates tend to fall because your risk of making impulsive decisions or taking chances diminishes. Insurance companies feel you are a lower risk as you age.
                    </p>
                    <p>
                      <strong>Your Location</strong><br/>
                      Where you live plays a role in your insurance rates too. For example, if you live out in a rural, low-crime area, you’ll pay low insurance rates. If you live in a high-crime city area, though, you may pay much higher rates. Again, the risk of a claim determines your premiums. A high-crime area puts you at higher risk of theft and vandalism. The insurance company needs to make up for this risk by charging higher premiums if they want to stay in business.
                    </p>
                    <p>
                      <strong>You Don’t Bundle Your Insurance</strong><br/>
                      If you have your home insurance with one agent, car insurance with another, and life insurance with yet another agent, you aren’t getting the bundle discount. Think of it as a loyalty discount. The more policies you have with an insurance company, the less they will charge you. They want you to stay a loyal customer, keeping their policies for many years.
                    </p>
                    <p>
                      If you have policies with different agents, see what you can do to consolidate. Typically, the largest discount occurs when you have your home and car insurance with the same agent. Check with your insurance agent to see what other discounts you may get.
                    </p>
                    <p>
                      <strong>Your Driving History</strong><br/>
                      It pays to be a safe driver. Insurance companies check your driving record. If you have a history of tickets or accidents, you may pay higher premiums. Again, drivers that take chances put the insurance company at a higher risk of paying a claim.
                    </p>
                    <p>
                      Any tickets you receive will be a part of your driving history. One ticket doesn’t mean you’ll definitely have higher premiums, but it might. It depends on the reason for the ticket. Did you park illegally? That may not have much of an effect. Were you drag racing or texting while driving? These incidents could cause your rates to rise because they increase your risk of causing an accident.
                    </p>
                    <p>
                      <strong>Your Insurance History</strong><br/>
                      Insurance companies look at your past insurance claims. They use this to predict how likely you are to file a claim in the future. The type of claim does make a difference, but your premiums will likely still increase. This means even if you file a claim for an accident that wasn’t your fault, you may still pay higher premiums because you were in an accident.
                    </p>
                    <p>
                      <strong>The Car you Drive</strong><br/>
                      Insurance companies have insurance rates for each make and model of a vehicle. If you choose to drive a racy red sports car, expect to pay much higher premiums than if you drove a Honda Accord, for example. Before you buy a new car, check with your insurance agent. They have ratings based on the likelihood of speeding in the car, its safety features, and its likelihood of being stolen or vandalized.
                    </p>
                    <p>
                      <strong>The Miles you Drive</strong><br/>
                      Your insurance agent needs to know your regular driving habits. How far do you commute to work? How many miles do you drive per year? The more miles that you drive, the higher your chances of being involved in an accident become.
                    </p>
                    <p>
                      <strong>Who Drives Your Car?</strong><br/>
                      Finally, the people that drive your car play a role in how much the insurance costs. If you have a 16-year old child driving your car, your rates will increase. If you have a husband with a poor driving history on your insurance, your rates will increase.
                    </p>
                    <p>
                      Auto insurance rates, in general, are on the rise, but you can help lower your rates. Know the risk factors that increase insurance rates and see if you can fix anything. You want to portray yourself as a low-risk driver that drives a safe car and minds the rules of the road for the best rates.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="Is Home Replacement Cost the Same as Market Value?"
              src={
                "../images/thereliefsupport-article2.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              Is Home Replacement Cost the Same as Market Value?
            </Typography>
            <p className={classes.articleExcerpt}>
              You buy home insurance to protect your home should a disaster occur and you need to rebuild it. But how do you know how much to insure it for? Sure, you know the value of your home, but that’s today. ...
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: 'Is Home Replacement Cost the Same as Market Value?',
                description: 'Is Home Replacement Cost the Same as Market Value?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Is Home Replacement Cost the Same as Market Value?</Typography>
                    <p>
                      By JMcHood February 8, 2021
                    </p>
                    <p>
                      You buy home insurance to protect your home should a disaster occur, and you need to rebuild it. But how do you know how much to insure it for? Sure, you know the value of your home, but that’s today. What’s the cost to rebuild it in the future when something could happen? This is the issue you face.
                    </p>
                    <p>
                      So how do insurance companies determine your home replacement value? Keep reading to find out.
                    </p>
                    <p>
                      <strong>Understanding Replacement Value</strong><br/>
                      First, it’s important to understand that replacement value isn’t the market value of your home. It’s also not the purchase price of the home. It’s the cost to rebuild or repair your home. That cost could be vastly different from the day that you purchase your home. So how do you make sure that you have proper coverage?
                    </p>
                    <p>
                      Your insurance agent should offer you the option for replacement value coverage. The insurance company uses a calculation base on the industry averages to rebuild a home to come up with your replacement value. This calculation includes the cost of material and labor. Again, this won’t be equal to your home’s market value. The market value includes the value of the land, which you won’t need to rebuild, so it’s not a part of the replacement value.
                    </p>
                    <p>
                      <strong>Verifying Your Replacement Value</strong><br/>
                      It can be tempting to accept an insurance company’s replacement value, but what if it’s wrong? You could find yourself without adequate insurance in the face of disaster. This is not the ideal time to find out. Rather than risking it, verify the replacement value offered by an insurance company with the following steps:
                      <ul>
                        <li>Find out the average construction cost per square foot in your area</li>
                        <li>Think of any upgrades you have in your home that could increase the cost of rebuilding</li>
                        <li>Consult with a professional appraiser</li>
                      </ul>
                      You can do the legwork and the math yourself if you want to save some money. Just make sure you kept careful records of any upgrades you made and that you can find out the current average cost to rebuild/replace your home at today’s rates.
                    </p>
                    <p>
                      If you want a professional’s opinion, though, it could further verify the value the insurance company provided. This way you know beyond a reasonable doubt that you have enough coverage. An appraiser can tell you the current construction costs for a home like yours so that you know how close your coverage is that the insurance company will provide.
                    </p>
                    <p>
                      <strong>The Risks With Replacement Cost Coverage</strong><br/>
                      You may have already guessed that there can be an issue with replacement cost coverage. If you figure out your home’s replacement cost today, that doesn’t mean the cost will be the same moving forward. Let’s say you have a home disaster in five years. Chances are that the costs to rebuild/replace your home in five years will be different than today.
                    </p>
                    <p>
                      Construction costs including labor and materials change often, which could affect the replacement cost of your home. You may also make upgrades and certain areas of your home may deteriorate. Each of these factors plays a role in how much it costs to replace your home. We recommend revisiting your home insurance policy on an annual basis to make sure that you have adequate replacement cost coverage.
                    </p>
                    <p>
                      <strong>Actual Cost Coverage versus Replacement Cost Coverage</strong><br/>
                      Replacement cost coverage typically costs more than actual cost coverage, but you get better protection. With actual cost coverage, the insurance company will only reimburse you for the cost of rebuilding the home today minus depreciation. Let’s say you’ve been in the home for 10 years. The insurance company would deduct the predetermined value for depreciation for 10 years. This could leave you with quite a deficit when it comes to paying to replace your home.
                    </p>
                    <p>
                      Replacement cost coverage is the most common type of coverage on home insurance today. Actual cost coverage often isn’t adequate and leaves homeowners with financial issues. Make sure that you shop around with different insurance agents to find out the average replacement cost coverage offered to you as well as to compare the premiums/deductibles available to determine the right insurance policy for you.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="Choosing the Right Life Insurance Plan"
              src={
                "../images/thereliefsupport-article3.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              Choosing the Right Life Insurance Plan
            </Typography>
            <p className={classes.articleExcerpt}>
              If you are an adult, you probably need life insurance. If you don’t know the first thing about it, buying the right life insurance policy can seem overwhelming. Not only do you have many program ...
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: 'Choosing the Right Life Insurance Plan',
                description: 'Choosing the Right Life Insurance Plan',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Choosing the Right Life Insurance Plan</Typography>
                    <p>
                      By JMcHood January 11, 2021
                    </p>
                    <p>
                      If you are an adult, you probably need life insurance. If you don’t know the first thing about it, buying the right life insurance policy can seem overwhelming. Not only do you have many program choices, but you have to choose the right amount and terms too.
                    </p>
                    <p>
                      Before you get overwhelmed, learn the basics that you should look for when buying your first policy.
                    </p>
                    <p>
                      <strong>Figure Out Why You Need a Life Insurance Policy</strong><br/>
                      First, consider why you need the life insurance policy in the first place. Do you have a family? Are you the breadwinner? Do you own a home? Do you want to pay for your kids to go to college? These are factors you must consider as they will help you determine the type of policy you need.
                    </p>
                    <p>
                      If you are young and just looking for a policy to cover your family in the event of your premature death, a term policy will likely suffice. This is often the most affordable life insurance as it has no cash value – it simply protects your family should you die unexpectedly. You can take out a policy that covers your income a few times over or at least that covers the value of your mortgage.
                    </p>
                    <p>
                      If you are older or want to combine your life insurance with an investment vehicle, you may want something more complex like a whole or universal life policy. These policies offer the benefits of a term policy but with a cash value that you may be able to use during your retirement years if necessary.
                    </p>
                    <p>
                      Once you know the type of policy you need and why you need it, you can start concerning yourself with the factors below.
                    </p>
                    <p>
                      <strong>Is it Affordable?</strong><br/>
                      Take a long hard look at the premiums on your life insurance policy. Can you comfortably afford them? What would happen if your income suddenly stopped? Would you still be able to work the payment into your monthly bills? Is it a payment you’d have a hard time making if you hit hard times?
                    </p>
                    <p>
                      The last thing you want is to let your life insurance policy lapse. When you pick up the pieces and apply for insurance again, chances are the premiums are going to be much higher. The older you get the more expensive premiums become. Insurers also become more restrictive as your health changes, making it harder to even get a policy. If you secure a policy when you are young, make sure it’s a premium that is attainable and not one you have to sacrifice to make so that you have a better chance of being able to pay it for many years to come.
                    </p>
                    <p>
                      <strong>What is the Term?</strong><br/>
                      Life insurance doesn’t last forever. You can buy policies for as little as 10 years or as long as 30 years and everywhere in between. Think about what protection you want. Do you want the policy for as long as you owe a mortgage? Do you want a policy in effect until you are retired? Only you know what term is best for you based on your income, lifestyle, and savings. If you only need a policy until your mortgage is paid off because you have adequate savings to get you through retirement, then choose the term that coincides with your mortgage. The shorter the term you choose, the lower your premiums will be.
                    </p>
                    <p>
                      <strong>How can you Access Your Money?</strong><br/>
                      If you take anything but a term life insurance policy, you’ll have access to your funds. You’ll need to know the circumstances you can withdraw the funds, though. For example, some policies require you to take a loan, in other words, pay interest on the money you withdraw. Others allow you to directly withdraw the funds but limit the amount you can take at one time.
                    </p>
                    <p>
                      Before you shop for a life insurance policy, you’ll want to have a thorough understanding of why you need the insurance in the first place. This way when you shop for the right policy, you’ll know what to look for and how to handle it. If it’s just temporary insurance to get you through a specific point in your life, term insurance may be just fine. If, however, you want an investment vehicle along with the protection life insurance offers, you may want a more complex policy that will require a little more digging and understanding of how the policy works.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const Steps = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer}>
        <Typography variant="h2" component="h2" className={classes.stepsHeading}>
          We Bring The Pros To You In <br/>
          3 Simple Steps
        </Typography>
        <Grid container spacing={10} justify="center" className={classes.tipsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={ScriptEditorImage} alt="Fill us in." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Fill us in.
            </Typography>
            <p className={classes.stepText}>
              Share some basic info to get customized service.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={PhotoGalleryImage} alt="Take your pick." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Take your pick.
            </Typography>
            <p className={classes.stepText}>
              Compare best estimates from top companies.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={LoginImage} alt="Ink your deal." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Ink your deal.
            </Typography>
            <p className={classes.stepText}>
              Connect with a local agent to get a quote or apply online.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const FooterGetQuote = () =>  {
  const classes = muiStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.footerGetQuote}>
      <Grid item xs={10} lg={7} xl={5}>
        <GetQuoteForm className={classes.getQuoteForm} inputErrorClassName={classes.footerGetQuoteInputError} inputLayout="inline" />
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = muiStyles();

  const [ modalProps, setModalProps ] = useState({ isOpen: false, title: '', description: '', content: ''});

  const closeModal = () =>{
    setModalProps({ isOpen: false, title: '', description: '', content: ''});
  }

  return (
    <Layout>
      <Hero />
      <Tips />
      <Blogs setModalProps={setModalProps} />
      <Steps />
      <FooterGetQuote />
      <Modal
        open={modalProps.isOpen}
        onClose={closeModal}
        className={classes.modal}
        id="blog-modal"
        disableBackdropClick
      >
        
        <div className={classes.modalWrapper}>
          <Container className={classes.modalContent}>
            <CancelIcon className={classes.modalCloseIcon} onClick={closeModal}/>
              {modalProps.content}
          </Container>
        </div>
      </Modal>
    </Layout>
  )
};

export default IndexPage;
